import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { userAtom } from "../../../recoil/user";

interface Prop {
  children: ReactNode;
}

const PrivateRoute: React.FC<Prop> = ({ children }) => {
  const user = useRecoilValue(userAtom);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/auth/signin" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
