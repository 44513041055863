import { keyframes } from "@emotion/react";

export const COLORS = {
  MAIN: "#EF6D93",
  SUB: "#3D4667",
  DARK_GRAY: "#1F1F1F",
  GRAY: "#898989",
  BLACK: "#525252",
  WHITE: "#FFFFFF",

  main: "#EF6D93",
  sub: "#3D4667",
  lightGray: "#E3E3E3",
  middleGray: "#4F4F4F",
  white: "#FFFFFF",
  subWhite: "#F8F8F8",
  black: "#000000",
  neutral050: "#FDFDFD",
  neutral100: "#F8F8F8",
  neutral200: "#E6E6E6",
  neutral300: "#D5D5D5",
  neutral400: "#B1B1b1",
  neutral500: "#909090",
  neutral600: "#6C6C6C",
  neutral700: "#464646",
  neutral800: "#222222",

  graphGreen: "#93D1A6",
  graphYellow: "#FFDE5C",

  pointRed: "#EA4072",
  lightPink: "#FAEFF2",
  lightPinkOutline: "#F1C8D3",
};

export const SHADOW = {
  basic: "8px 8px 15px rgba(0, 0, 0, 0.08)",
};

export const ANIMATIONS = {
  fadeInLeft: keyframes`0% { transform: translateX(100%); opacity: 0; } 100% { transform: translateX(0%); opacity: 1; }`,
  fadeOut: keyframes`0% { transform: translateX(0%); } 100% { transform: translateX(100%); }`,
  rotate: keyframes`0% { transform: rotate(0deg); } 25% { transform: rotate(8deg); } 50% { transform: rotate(0deg); } 75% { transform: rotate(8deg); } 100% { transform: rotate(0deg); }`,
};

export const DEFAULT_WIDTH = "1080px";

export const FONT_SIZE = {
  xxs: "10px",
  xs: "12px",
  s: "14px",
  m: "16px",
  l: "18px",
  xl: "20px",
  xxl: "22px",
  xxxl: "24px",
};
