import styled from "@emotion/styled";
import { AccordionDetails, Button, Menu, MenuItem } from "@mui/material";

import { COLORS, FONT_SIZE, SHADOW } from "config/styles";

import RouteBtn from "components/common/routeBtn";
import StrongText from "components/common/strongText";

import { ReactComponent as BellImg } from "assets/images/header/alert.svg";

// Alert
export const AlertCtnr = styled(Button)`
  min-width: inherit;
  padding: 0px;
  margin-right: 24px;

  &:hover {
    background-color: transparent;
  }
`;

export const AlertIcon = styled.div`
  width: 44px;
  height: 44px;
`;

export const AlertImg = styled(BellImg)``;

export const AlertPopup = styled(Menu)`
  & .MuiPaper-root {
    width: 320px;
    max-height: 664px;
    border: 1px solid ${COLORS.neutral200};
    border-radius: 8px;
    background-color: ${COLORS.white};
    box-shadow: ${SHADOW.basic};
  }

  & .MuiList-root {
    padding: 0px;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  flex-direction: column;
  padding: 43px 33px;

  :hover {
    background-color: transparent;
  }
`;

export const AlertPopupHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: ${FONT_SIZE.xxxl};
  color: ${COLORS.black};
`;

export const CustomRouteBtn = styled(RouteBtn)`
  height: auto;
  border: 1px solid ${COLORS.neutral500};
  background-color: transparent;
  box-shadow: none;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral700};
  padding: 5px 10px;
`;

export const AlertListCtnr = styled.div`
  width: 100%;
`;

export const AlertList = styled.div``;

export const ListTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const CustomStrongText = styled(StrongText)`
  font-size: ${FONT_SIZE.xs};
`;

export const Date = styled.div`
  font-weight: 500;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral500};

  span {
    font-weight: 700;
    color: ${COLORS.neutral700};
  }
`;

export const ListBot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.neutral200};
  padding: 16px 0px;

  &:first-of-type {
    padding: 40px 0px 16px 0px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ListTextCtnr = styled.div`
  width: 220px;
`;

export const ListTit = styled.p`
  font-weight: 700;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral700};
  white-space: break-spaces;
`;

export const ListContents = styled.p`
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral600};
  white-space: break-spaces;
  margin-top: 8px;
`;

export const MoreBtn = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
