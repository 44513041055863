import React from "react";

import AsyncBoundary from "../asyncBoundary";
import DesktopHeader from "./desktopHeader";

const Header = () => {
  return (
    // <AsyncBoundary
    //   errorFallback={<div>에러가 발생했습니다.</div>}
    //   suspenseFallback={<div>로딩중</div>}
    // >
    <DesktopHeader />
    // </AsyncBoundary>
  );
};

export default Header;
