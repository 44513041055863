import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as S from "./style";

import { PATH_INVOICE, PATH_PROFILE, PATH_SENDING } from "routes/paths";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { isServiceAtom } from "../../../../recoil/service";

import usePartnerDash from "hooks/partner/usePartnerDash";
import useSignOut from "hooks/auth/useSignOut";

import { getS3Path } from "services/invoice/utils";
import { IPartner } from "services/partner/types";

import PartnerNmWithBadge from "components/common/partnerNmWithBadge";

import { partnerDashNav } from "utils/const";

interface Props {
  partner?: IPartner;
}

const label = { inputProps: { "aria-label": "Service Switch" } };

const ProfileAside: React.FC<Props> = ({ partner }) => {
  const partnerNm = partner ? partner.officeNm : "견적주세요";
  const officeImg = partner ? partner.officeImg : "default/default_user.png";
  const { data: partnerDash } = usePartnerDash();
  const { signOut } = useSignOut();
  const [isShowToolTip, setIsShowToolTip] = useState(false);
  const isService = useRecoilValue(isServiceAtom);
  // API(hooks) 연결 필요
  const setIsService = useSetRecoilState(isServiceAtom);

  const handleMouseOver = () => {
    setIsShowToolTip(true);
  };

  const handleMouseLeave = () => {
    setIsShowToolTip(false);
  };

  const handleChange = () => {
    // API(hooks) 연결 필요
    setIsService(!isService);
  };

  return (
    <S.ProfileAside>
      <S.OfficeImg
        url={getS3Path(officeImg || "default/default_user.png")}
      ></S.OfficeImg>
      <PartnerNmWithBadge partnerNm={partnerNm} />
      <S.PartnerDash>
        <S.PartnerDashList>
          <S.ListTitle>나의평점</S.ListTitle>
          <S.NavNum>{partnerDash?.avgStar}</S.NavNum>
        </S.PartnerDashList>
        <S.NavList>
          <S.CustomLink to={PATH_SENDING.root}>
            <S.ListTitle>받은요청</S.ListTitle>
            <S.NavNum>{partnerDash?.recvCnt}</S.NavNum>
          </S.CustomLink>
        </S.NavList>
        <S.NavList>
          <S.CustomLink to={PATH_INVOICE.root}>
            <S.ListTitle>보낸견적</S.ListTitle>
            <S.NavNum>{partnerDash?.invoiceCnt}</S.NavNum>
          </S.CustomLink>
        </S.NavList>
      </S.PartnerDash>
      <S.Nav>
        {partnerDashNav.map(({ title, path }) => (
          <S.NavList key={title}>
            <S.CustomLink to={path}>
              <S.ListTitle>{title}</S.ListTitle>
            </S.CustomLink>
          </S.NavList>
        ))}
      </S.Nav>
      <S.Service>
        <S.ServiceRegister>
          <Link to={PATH_PROFILE.category}>서비스 등록</Link>
        </S.ServiceRegister>
        <S.ServiceStart>
          <S.ServiceStartTitle>
            서비스 시작
            <S.ServiceTooltipCtnr>
              <S.HelpImg
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              />
              <S.ServiceTooltip isShow={isShowToolTip}>
                OFF 설정시 요청서 받기가
                <br />
                일시 중지됩니다.
              </S.ServiceTooltip>
            </S.ServiceTooltipCtnr>
          </S.ServiceStartTitle>
          <S.ToggleBtn
            {...label}
            size="small"
            checked={isService}
            onChange={handleChange}
          />
        </S.ServiceStart>
      </S.Service>
      <S.SignOut onClick={signOut}>로그아웃</S.SignOut>
    </S.ProfileAside>
  );
};

export default ProfileAside;
