import styled from "@emotion/styled";
import { COLORS } from "config/styles";

export const CommonLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  // Minus header, footer size
  min-height: calc(100vh - 80px - 232px);
  background-color: ${COLORS.neutral100};
`;

export const Main = styled.div`
  /* position: relative; */
  display: flex;
  justify-content: center;
  width: 1080px;
  margin: 48px 0px;
`;

export const PageMainCtnr = styled.div`
  width: 794px;
  margin-left: 86px;
`;
