import styled from "@emotion/styled";
import { Accordion, AccordionSummary } from "@mui/material";

import { ReactComponent as TelephoneImg } from "assets/images/footer/telephone.svg";
import { ANIMATIONS, COLORS } from "config/styles";

export const FooterContents = styled.div``;

export const Office = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
`;

export const Title = styled(AccordionSummary)`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${COLORS.neutral200};

  svg {
    width: 16px;
    height: 16px;
    margin-left: 9px;
    cursor: pointer;
  }
`;

export const Tel = styled.div`
  display: inline-block;
  background-color: ${COLORS.neutral600};
  border-radius: 50px;
  font-weight: 700;
  color: ${COLORS.white};
  padding: 12px 20px;
  margin-top: 16px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TelImg = styled(TelephoneImg)``;

export const TelNum = styled.div`
  font-weight: 700;
  margin-left: 11px;
`;
