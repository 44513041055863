import axios, { AxiosError } from "axios";

export const API = axios.create();

export const isAxiosError = (error: any): error is AxiosError => {
  return error?.isAxiosError;
};

const globalErrorHandler = async (error?: any) => {
  if (isAxiosError(error)) {
    const status = error.response?.status;
    if (status) {
      if (status >= 500) {
        alert("서버에 문제가 발생했습니다. 빠른 정상화를 위해 노력하겠습니다.");
      } else {
        // Refresh & Retry
        //
      }
    }
  }
  throw error;
};

API.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api/v1`;
API.interceptors.response.use(undefined, globalErrorHandler);
