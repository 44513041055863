export const INVALID_EMAIL = "이메일 형식은 @과 .를 포함해야 합니다.";

export const SUCCESS_MSG = {
  // auth
  sms: "문자가 전송되었습니다.",
  signup: "회원가입이 완료되었습니다.",
  passwordChange: "비밀번호가 성공적으로 변경되었습니다.",

  // profile
  profile: "프로필이 성공적으로 등록되었습니다.",

  // partnership
  partnership: "전송에 성공했습니다.",
};

export const ERROR_MSG = {
  // auth
  sms: "SMS 요청에 실패했습니다. 잠시 후 다시 시도해주세요.",
  signup: "회원가입에 실패했습니다.",
  authCode: "인증번호가 일치하지 않습니다.",
  passwordChange: "비밀번호 변경에 실패했습니다. 잠시 후 다시 시도해주세요.",
  email: "이메일이 일치하지 않습니다.",
  findId: "문제가 발생했습니다. 잠시 후 다시 시도해주세요.",

  // profile
  profile: "프로필 등록에 실패했습니다. 잠시 후 다시 시도해주세요.",
  service: "서비스 변경에 실패했습니다. 잠시 후 다시 시도해주세요.",
  category: "서비스를 불러오는데 실패했습니다.",

  // sending

  // invoice
  invoiceSend: "견적 보내기에 실패했습니다. 잠시 후 다시 시도해주세요.",

  // partnership
  partnership: "전송에 실패했습니다. 잠시 후 다시 시도해주세요.",

  // chat
  chat: "채팅을 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.",
};

export const QUESTION_MSG = {
  // auth
  signOut: "로그아웃 하시겠습니까?",

  // profile
  category: "선택한 항목을 삭제할까요?",
};
