import styled from "@emotion/styled";
import { COLORS, FONT_SIZE } from "../../../../../config/styles";

export const AuthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: ${FONT_SIZE.s};
    padding: 10px;
    cursor: pointer;
  }
`;
