import React from "react";
import { Link } from "react-router-dom";

import * as S from "./style";

import useLogout from "hooks/auth/useSignOut";
import usePartner from "hooks/partner/usePartner";

import { PATH_AUTH, PATH_PROFILE } from "routes/paths";
import { getS3Path } from "services/invoice/utils";
import ProfileThumb from "./profileThumb";

const AuthBtnWrapper = () => {
  const { isLoading, data: partner } = usePartner();
  const { signOut } = useLogout();

  return (
    <S.AuthWrapper>
      {partner ? (
        // <Link to={PATH_PROFILE.root}>
        <ProfileThumb partner={partner} />
      ) : (
        // <S.PartnerCtnr>
        //   <S.PartnerImg src={getS3Path(partner.officeImg)} />
        // </S.PartnerCtnr>
        // </Link>
        <>
          <Link to={PATH_AUTH.signin}>로그인</Link>
          <Link to={PATH_AUTH.signup}>회원가입</Link>
        </>
      )}
    </S.AuthWrapper>
  );
};

export default AuthBtnWrapper;
