import useSignOut from "hooks/auth/useSignOut";
import useDropdown from "hooks/common/useDropdown";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PATH_PROFILE } from "routes/paths";
import { getS3Path } from "services/invoice/utils";
import { IPartner } from "services/partner/types";

import * as S from "./style";

interface Props {
  partner: IPartner;
}

const ProfileThumb: React.FC<Props> = ({ partner }) => {
  const { anchorEl, open, handleClick, handleClose } = useDropdown();
  const { signOut } = useSignOut();

  return (
    <>
      <S.Partner
        id="profile-button"
        aria-controls={open ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <S.PartnerThumbImg
          src={getS3Path(partner.partnerImg || "default/default_user.png")}
        />
      </S.Partner>
      <S.PartnerPopup
        id="alert-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "alert-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <S.CustomMenuItem onClick={handleClose} disableRipple>
          <S.PartnerImgCtnr>
            <S.PartnerImg
              src={getS3Path(partner.partnerImg || "default/default_user.png")}
            />
          </S.PartnerImgCtnr>
          <S.CustomPartnerNmWithBadge partnerNm={partner.partnerNm} />
          <S.ProfilNav>
            <S.ProfileRoot to={PATH_PROFILE.root}>프로필설정</S.ProfileRoot>
            <S.SignOut onClick={signOut}>로그아웃</S.SignOut>
          </S.ProfilNav>
        </S.CustomMenuItem>
      </S.PartnerPopup>
    </>
  );
};

export default ProfileThumb;
