import styled from "@emotion/styled";

import { COLORS, DEFAULT_WIDTH } from "../../../config/styles";

export const FooterTag = styled.footer`
  width: 100%;
  background-color: ${COLORS.neutral700};
`;

export const FooterCtnr = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${DEFAULT_WIDTH};
`;

export const FooterHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${COLORS.neutral600};
`;

export const FooterBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.neutral700};
  padding: 38px 0px 46px 0px;
`;

export const FooterContents = styled.div``;
