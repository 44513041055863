import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AsyncBoundary from "components/common/asyncBoundary";
import RootLoading from "components/common/loadingUI/rootLoading";
import ScrollToTop from "components/common/scrollToTop";
import NotFoundPage from "pages/404";
import React from "react";
import Modal from "./components/common/modal";
import Router from "./routes";

console.warn = console.error = () => {};

function App() {
  return (
    <AsyncBoundary
      errorFallback={<NotFoundPage />}
      suspenseFallback={<RootLoading />}
    >
      <>
        <ScrollToTop />
        <Modal />
        <Router />
        {process.env.NODE_ENV === "production" ? null : (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </>
    </AsyncBoundary>
  );
}

export default App;
