import React from "react";

import * as S from "./style";

import { ModalWrapperProps } from "../../basicModal/basicModalWrapper";
import { Dialog } from "@mui/material";

const LocationModalWrapper: React.FC<ModalWrapperProps> = ({
  visible,
  children,
  onClose,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <S.LocationModalWrapper>{children}</S.LocationModalWrapper>
    </Dialog>
  );
};

export default LocationModalWrapper;
