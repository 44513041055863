import React from "react";

import * as S from "./style";

const OfficeInfo = () => {
  return (
    <S.TableCntr>
      <S.TableRow>
        <S.RowTitle>회사명</S.RowTitle>
        <S.RowContents>(주)케이바이러스연구소</S.RowContents>
      </S.TableRow>
      <S.TableRow>
        <S.RowTitle>주소</S.RowTitle>
        <S.RowContents>
          서울특별시 강서구 등촌동 628-5 더스카이밸리5차 지식산업센터B107호
        </S.RowContents>
      </S.TableRow>
      <S.TableRow>
        <S.RowTitle>사업자번호</S.RowTitle>
        <S.RowContents>837-86-01521</S.RowContents>
      </S.TableRow>
      <S.TableRow>
        <S.RowTitle>이메일</S.RowTitle>
        <S.RowContents>givemeprice@naver.com</S.RowContents>
      </S.TableRow>
      <S.TableRow>
        <S.RowTitle>통신판매업 번호</S.RowTitle>
        <S.RowContents>021-서울강서-2777</S.RowContents>
      </S.TableRow>
      <S.TableRow>
        <S.RowTitle>FAX</S.RowTitle>
        <S.RowContents>000-000-000</S.RowContents>
      </S.TableRow>
    </S.TableCntr>
  );
};

export default OfficeInfo;
