import styled from "@emotion/styled";
import { Switch } from "@mui/material";

import { ReactComponent as HelpIcon } from "assets/images/common/help-btn.svg";
import { Link } from "react-router-dom";

import { COLORS, FONT_SIZE, SHADOW } from "../../../../config/styles";

export const ProfileAside = styled.div`
  width: 200px;
  height: fit-content;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: ${SHADOW.basic};
  padding: 20px;
`;

interface IOfficeImgProps {
  url: string;
}

export const OfficeImg = styled.div<IOfficeImgProps>`
  width: 136px;
  height: 136px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 6px;
  margin: 0px auto 8px auto;
`;

export const PartnerDash = styled.ul`
  border-top: 1px solid ${COLORS.neutral200};
  border-bottom: 1px solid ${COLORS.neutral200};
  padding: 16px 0px;
`;

export const PartnerDashList = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral600};
`;

export const NavNum = styled.p`
  font-weight: 700;
  color: ${COLORS.neutral600};
`;

export const ListTitle = styled.div``;

export const Nav = styled.ul`
  border-bottom: 1px solid ${COLORS.neutral200};
  padding: 16px 0px;
`;

export const NavList = styled.li`
  font-weight: 500;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral600};
  margin-top: 20px;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0px;
  }

  &:hover {
    font-weight: 700;
    color: ${COLORS.neutral800};
  }
`;

export const CustomLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Service = styled.ul`
  border-bottom: 1px solid ${COLORS.neutral200};
  padding: 26px 0px;
`;

export const ServiceRegister = styled.li`
  font-weight: 500;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral600};

  > a {
    display: block;
  }

  &:hover {
    font-weight: 700;
    color: ${COLORS.neutral800};
  }
`;

export const ServiceStart = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const ServiceStartTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral600};

  svg {
    margin-left: 6px;
  }
`;

export const ServiceTooltipCtnr = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HelpImg = styled(HelpIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

interface IServiceTooltip {
  isShow: boolean;
}

export const ServiceTooltip = styled.div<IServiceTooltip>`
  position: absolute;
  top: calc(100% + 7px);
  left: 0%;
  display: ${({ isShow }) => (isShow ? "block" : "none")};
  width: max-content;
  color: ${COLORS.neutral700};
  font-size: ${FONT_SIZE.xs};
  line-height: 125%;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral400};
  border-radius: 4px;
  padding: 8px;
`;

export const ToggleBtn = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${COLORS.main};

    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked &:hover {
      background-color: alpha(${COLORS.main}, 0.5);
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${COLORS.main};
  }
`;

export const SignOut = styled.div`
  font-weight: 500;
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral600};
  margin-top: 23px;
  padding: 5px 0px;
  cursor: pointer;

  &:hover {
    font-weight: 700;
    color: ${COLORS.neutral800};
  }
`;
