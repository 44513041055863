import React, { HTMLAttributes } from "react";
import RouteBtn from "components/common/routeBtn";
import { PATH_PROFILE } from "routes/paths";

import ProfileImg from "assets/images/common/profile-icon.png";

import * as S from "./style";
import PageContents from "components/common/pageContents";

interface Props extends HTMLAttributes<HTMLDivElement> {
  bigTit?: string;
  mainText?: string;
  subText?: string[];
  image?: string;
  handleClick?: VoidFunction;
  btnText?: string;
  isBtnIcon?: boolean;
  nextBtn?: boolean;
  onClickNextBtn?: VoidFunction;
}

const EmptyBg: React.FC<Props> = ({
  bigTit,
  mainText,
  subText,
  image,
  handleClick,
  btnText,
  isBtnIcon = false,
  nextBtn = false,
  onClickNextBtn,
  ...props
}) => {
  return (
    <S.EmptyBg {...props}>
      {bigTit && <p>{bigTit}</p>}
      <S.EmptyBody>
        <img src={image} alt={mainText} />
        <p>{mainText}</p>
        {subText &&
          subText.map((line) => <S.SubText key={line}>{line}</S.SubText>)}
        {handleClick && (
          <S.CustomRouteBtn handleClick={handleClick} isIcon={isBtnIcon}>
            {btnText}
          </S.CustomRouteBtn>
        )}
        {nextBtn && (
          <S.NextBtn onClick={onClickNextBtn}>다음에 등록하기</S.NextBtn>
        )}
      </S.EmptyBody>
    </S.EmptyBg>
  );
};

export default EmptyBg;
