import styled from "@emotion/styled";

import { COLORS, FONT_SIZE } from "config/styles";

export const TwoBtnModalContainer = styled.div`
  min-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const Title = styled.p`
  font-weight: 700;
`;

export const Contents = styled.p`
  font-size: ${FONT_SIZE.l};
  margin-top: 40px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin-top: 50px;

  button {
    border-radius: 8px;
    background-color: ${COLORS.main};
    border: 0px;
    color: ${COLORS.white};
    padding: 12px 34px;

    &:first-of-type {
      background-color: transparent;
      border: 1px solid ${COLORS.neutral500};
      color: ${COLORS.neutral700};
      margin-right: 8px;
    }
  }
`;
