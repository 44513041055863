import { useQueryClient } from "@tanstack/react-query";
import useModal from "hooks/common/useModal";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  getPartnerQueryKey,
  getPartnerDashQueryKey,
  getPartnerStatsByMonthQueryKey,
} from "services/partner/types";
import { QUESTION_MSG } from "utils/message";
import { userAtom } from "../../recoil/user";

/**
 * 로그아웃합니다.
 * 로그인 데이터를 삭제하고 localsorage를 비웁니다.
 */
const useSignOut = () => {
  const resetUser = useResetRecoilState(userAtom);
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const signOut = () => {
    openModal({
      key: "twoBtnModal",
      contents: QUESTION_MSG.signOut,
      callback: () => {
        queryClient.removeQueries([getPartnerQueryKey]);
        queryClient.removeQueries([getPartnerDashQueryKey]);
        queryClient.removeQueries([getPartnerStatsByMonthQueryKey]);
        resetUser();
      },
    });
  };

  return { signOut };
};

export default useSignOut;
