export const checkImageFile = (file: string) => {
  const extension = file.slice(file.lastIndexOf(".") + 1).toLowerCase();

  if (
    extension !== "jpg" &&
    extension !== "jpeg" &&
    extension !== "png" &&
    extension !== "gif" &&
    extension !== "bmp"
  ) {
    return false;
  }
  return true;
};

export const getS3Path = (fileName: string) => {
  return `${process.env.REACT_APP_S3_HOST}/${fileName}`;
};

export const setIsServiceInLocalStorage = (isService: boolean) => {
  window.localStorage.setItem("service", JSON.stringify(isService));
};

export const removeIsServiceInLocalStorage = () => {
  window.localStorage.removeItem("service");
};

export const getIsServiceInLocalStorage = () => {
  const isService = window.localStorage.getItem("service");
  return isService ? JSON.parse(isService) : false;
};
