function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_SENDING = "/sending";
const ROOTS_INVOICE = "/invoice";
const ROOTS_CHAT = "/chat";
const ROOTS_PROFILE = "/profile";
const ROOTS_ALERT = "/alert";
const ROOTS_FAQ = "/faq";
const ROOTS_NOTICE = "/notice";

export const PATH_AUTH = {
  signin: path(ROOTS_AUTH, "/signin"),
  signup: path(ROOTS_AUTH, "/signup"),
  find: path(ROOTS_AUTH, "/find"),
  changePassword: path(ROOTS_AUTH, "/change-password"),
};

export const PATH_SENDING = {
  root: ROOTS_SENDING,
  send: (sendingId: number) => path(ROOTS_SENDING, `/${sendingId}`),
};

export const PATH_INVOICE = {
  root: ROOTS_INVOICE,
  sent: (invoiceId: number) => path(ROOTS_INVOICE, `/${invoiceId}`),
};

export const PATH_CHAT = {
  all: path(ROOTS_CHAT, "/all"),
  detail: (chatId: number) => path(ROOTS_CHAT, `/${chatId}`),
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
  register: path(ROOTS_PROFILE, "/register"),
  category: path(ROOTS_PROFILE, "/category"),
};

export const PATH_ALERT = {
  all: path(ROOTS_ALERT, "/all"),
  detail: (alertId: number) => path(ROOTS_ALERT, `/${alertId}`),
};

export const PATH_FAQ = {
  all: path(ROOTS_FAQ, "/all"),
  detail: (faqId: number) => path(ROOTS_FAQ, `/${faqId}`),
};

export const PATH_NOTICE = {
  all: path(ROOTS_NOTICE, "/all"),
  detail: (noticeId: number) => path(ROOTS_NOTICE, `/${noticeId}`),
};

export const PATH_PAGE = {
  root: "/",
  partnership: "/partnership",
  notice: "/notice",
  page404: "/404",
  policy: "/policy",
};
