import { useQuery } from "@tanstack/react-query";
import usePartner from "hooks/partner/usePartner";
import { useState } from "react";
import { getAlertListApi } from "services/alert/apis";
import { getAlertListApiQueryKey } from "services/alert/types";

import usePagination from "../common/usePagination";

/**
 * 알림 리스트를 반환합니다.
 * 현재 pagination, tab별 filter 기능 구현되어 있지 않습니다.
 * 해당 기능 구현 전 백엔드와 소통 부탁드립니다.(API에 추가)
 */
const useAlert = () => {
  const { data: partner } = usePartner();
  const { page, handleChange } = usePagination(); // pagination 추가하기
  const [category, setCategory] = useState("전체");
  const { data: alertList } = useQuery([getAlertListApiQueryKey], () =>
    getAlertListApi({ userId: partner?.id, partneryn: true })
  );

  return {
    page,
    handleChange,
    category,
    setCategory,
    alertList,
  };
};

export default useAlert;
