import styled from "@emotion/styled";
import { COLORS, FONT_SIZE } from "config/styles";

export const UserName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;

  p {
    color: ${COLORS.white};
    font-size: ${FONT_SIZE.xxs};
    line-height: 100%;
    background-color: #859dba;
    border: 1px solid #6381a6;
    border-radius: 3px;
    padding: 4px;
  }

  span {
    font-size: ${FONT_SIZE.xs};
    font-weight: 500;
    margin-left: 6px;
  }
`;
