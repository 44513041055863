import React from "react";

import * as S from "./style";

import ContentsError from "components/error/contentsError";

const NotFoundPage = () => {
  return (
    <S.NotFound>
      <S.NotFoundContainer>
        <ContentsError />
      </S.NotFoundContainer>
    </S.NotFound>
  );
};

export default NotFoundPage;
