import { useState } from "react";

/**
 * 페이지네이션 기능을 지원하기 위한 훅입니다.
 * 고도화가 필요합니다.
 */
const usePagination = () => {
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return { page, setPage, handleChange };
};

export default usePagination;
