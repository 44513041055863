import React, { HTMLAttributes } from "react";

import * as S from "./style";

interface Props extends HTMLAttributes<HTMLElement> {
  text: string;
  fontSize?: string;
}

const StrongText: React.FC<Props> = ({ text, fontSize = "16px", ...props }) => {
  return (
    <S.Text {...props} fontSize={fontSize}>
      {text}
    </S.Text>
  );
};

export default StrongText;
