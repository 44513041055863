import { atom, AtomEffect, DefaultValue } from "recoil";
import {
  getIsServiceInLocalStorage,
  setIsServiceInLocalStorage,
} from "services/invoice/utils";

const handleChangeServiceAtom: AtomEffect<any> = ({ setSelf, onSet }) => {
  setSelf(getIsServiceInLocalStorage());
  onSet((newValue) => {
    setIsServiceInLocalStorage(newValue);
  });
};

export const isServiceAtom = atom<boolean>({
  key: "isServiceAtom",
  default: false,
  effects_UNSTABLE: [handleChangeServiceAtom],
});
