import { SignInUserInfo } from "./types";

export const setUserInLocalStorage = (user: SignInUserInfo) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserInLocalStorage = () => {
  window.localStorage.removeItem("user");
};

export const getUserInLocalStorage = () => {
  const user = window.localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const makeFormData = (params: any) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    searchParams.append(key, params[key]);
  });
  return searchParams;
};
