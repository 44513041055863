import { ICommonResponseData, ILocal } from "services/common/types";

export const getPartnerQueryKey = "getPartnerQuery";
export const getPartnerDashQueryKey = "getPartnerDashQuery";
export const getPartnerStatsByMonthQueryKey = "getPartnerStatsByMonth";
export const searchItemQueryKey = "searchItemQuery";
export const getCategoryListQueryKey = "getCategoryListQuery";

export interface IPartner {
  id: number;
  partnerNm: string;
  partnerImg: string;
  address: string;
  detailAddress: string;
  officeImg: string;
  partnerType: string;
  officeNumber: string;
  officeNm: string;
  officeFile: string;
  introduce: string;
  phone: string;
  email: string;
  localId: number;
  partnerTypeOrder: IPartnerTypeOrder[];
  local: ILocal;
  file: string[];
}

export interface IPartnerResponseData extends ICommonResponseData {
  data: IPartner;
}

export interface IPartnerDash {
  invoiceCnt: number;
  tradeCnt: number;
  recvCnt: number;
  avgStar: string;
  reviewCnt: number;
}

export interface IPartnerDashResponseData extends ICommonResponseData {
  data: IPartnerDash;
}

export interface IPartnerStatsByMonth {
  id: number;
  month: string;
  invoiceCnt: number;
  tradeCnt: number;
  recvCnt: number;
}

export interface IPartnerStatsByMonthResponseData extends ICommonResponseData {
  data: IPartnerStatsByMonth[];
}

export interface IPartnerTypeOrder {
  id: number;
  itemNm: string;
  itemId: number;
}

export interface IPartnerProfileInput {
  partnerId: number;
  partnerNm?: string;
  partnerImg?: string;
  officeImg?: string;
  officeNm?: string;
  address?: string;
  detailAddress?: string;
  officeNumber?: string;
  introduce?: string;
  officeFile?: string;
  officePhone?: string;
  partnerType?: string;
  phone?: string;
}

// Search Item
export interface ISearchItemResponseData extends ICommonResponseData {
  data: IItem[];
}

export interface IItem {
  itemNm: string;
  id: number;
}

// Cateogry List
export interface ICategoryList {
  id: number;
  itemNm: string;
  moItemId: number;
  sequence: number;
  data: ICategoryList[];
}

export interface ICategoryListResponseData extends ICommonResponseData {
  data: ICategoryList[];
}
