import React from "react";
import { Link } from "react-router-dom";

import * as S from "./style";

import { footerNav, footerSNS } from "utils/const";

const FooterNav = () => {
  return (
    <>
      <S.Nav>
        {footerNav.map(({ title, path }) => (
          <S.NavList key={title + path}>
            {path.includes("http") ? (
              <a href={path} target="_blank">
                {title}
              </a>
            ) : (
              <Link to={path}>{title}</Link>
            )}
          </S.NavList>
        ))}
      </S.Nav>
      <S.SNS>
        {footerSNS.map(({ img, alt, url }) => (
          <S.SNSList key={img + url}>
            <a href={url} target="_blank">
              <img src={img} alt={alt} />
            </a>
          </S.SNSList>
        ))}
      </S.SNS>
    </>
  );
};

export default FooterNav;
