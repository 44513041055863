import styled from "@emotion/styled";

import {
  COLORS,
  FONT_SIZE,
  DEFAULT_WIDTH,
  SHADOW,
} from "../../../../config/styles";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import RouteBtn from "components/common/routeBtn";

export interface HeaderTagProp {
  pathname: string;
}

export const HeaderTag = styled.header<HeaderTagProp>`
  width: 100%;
  height: 80px;
  border-bottom: ${({ pathname }) =>
    pathname === "/" ? "0px" : `1px solid ${COLORS.lightGray}`};
`;

export const HeaderWrapper = styled.div`
  width: ${DEFAULT_WIDTH};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

export const Logo = styled.div`
  width: 129px;

  svg {
    width: 100%;
  }
`;

export const HeaderContentsCtnr = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Nav = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 24px;

    li {
      font-size: ${FONT_SIZE.s};
      padding: 10px 8px;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
`;
