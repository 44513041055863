import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./global.css";

import { QueryClientProvider } from "@tanstack/react-query";

import { RecoilRoot } from "recoil";

import { generateQueryClient } from "./config/query";

import ScrollToTop from "./components/common/scrollToTop";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "utils/theme";
import App from "App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RecoilRoot>
    <QueryClientProvider client={generateQueryClient()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </RecoilRoot>
);
