import React from "react";

import * as S from "./style";

import TwoBtnModalWrapper from "./twoBtnModalWrapper";
import { ModalProps } from "../basicModal";

const TwoBtnModal: React.FC<ModalProps> = ({
  visible,
  title,
  contents,
  callback,
  onClose,
}) => {
  const onClickDelete = () => {
    callback && callback();
    onClose();
  };

  return (
    <TwoBtnModalWrapper visible={visible} onClose={onClose}>
      <S.TwoBtnModalContainer>
        <S.Title id="dialog-title">{title}</S.Title>
        <S.Contents id="dialog-description">{contents}</S.Contents>
        <S.ButtonWrap>
          <button onClick={() => onClose()}>취소</button>
          <button onClick={() => onClickDelete()}>확인</button>
        </S.ButtonWrap>
      </S.TwoBtnModalContainer>
    </TwoBtnModalWrapper>
  );
};

export default TwoBtnModal;
