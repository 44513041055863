import styled from "@emotion/styled";

export const DownloadAppBtnCtnr = styled.ul`
  display: flex;
  align-self: flex-start;
`;

export const DownloadAppBtn = styled.li`
  width: 156px;
  height: 56px;
  margin-left: 8px;
`;

export const Googleplay = styled.img`
  width: 100%;
  height: 100%;
`;

export const Appstore = styled.img`
  width: 100%;
  height: 100%;
`;
