import React from "react";

import * as S from "./style";

import DownloadApp from "./downloadApp";
import FooterNav from "./footerNav";
import FooterContents from "./footerContents";

const Footer = () => (
  <S.FooterTag>
    <S.FooterHeader>
      <S.FooterCtnr>
        <FooterNav />
      </S.FooterCtnr>
    </S.FooterHeader>
    <S.FooterBody>
      <S.FooterCtnr>
        <FooterContents />
        <DownloadApp />
      </S.FooterCtnr>
    </S.FooterBody>
  </S.FooterTag>
);

export default Footer;
