import React, { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { modalStateAtom } from "../../../recoil/modal";
import BasicModal from "./basicModal";
import LocationModal from "./locationModal";
import TwoBtnModal from "./twoBtnModal";

type ModalKey = "basicModal" | "twoBtnModal" | "locationModal";

interface ModalContents {
  title?: string;
  contents?: string;
  callback?: any;
}

export interface ModalType extends ModalContents {
  key: ModalKey | string;
}

const Modal = () => {
  const { key, title, contents, callback } = useRecoilValue(modalStateAtom);
  const resetModalState = useResetRecoilState(modalStateAtom);

  return (
    <>
      <BasicModal
        visible={key === "basicModal"}
        title={title}
        contents={contents}
        callback={callback}
        onClose={resetModalState}
      />
      <TwoBtnModal
        visible={key === "twoBtnModal"}
        title={title}
        contents={contents}
        callback={callback}
        onClose={resetModalState}
      />
      <LocationModal
        visible={key === "locationModal"}
        onClose={resetModalState}
        callback={callback}
      />
    </>
  );
};

export default Modal;
