import React from "react";

import * as S from "./style";

import { Resetter } from "recoil";

import BasicModalWrapper from "./basicModalWrapper";

export interface ModalProps {
  visible: boolean;
  title?: string;
  contents?: string;
  callback?: () => any;
  onClose: Resetter;
}

const BasicModal: React.FC<ModalProps> = ({ visible, contents, onClose }) => {
  return (
    <BasicModalWrapper visible={visible} onClose={onClose}>
      <S.BasicModalContainer>
        <p id="modal-description">{contents}</p>
      </S.BasicModalContainer>
    </BasicModalWrapper>
  );
};

export default BasicModal;
