import React from "react";
import { Dialog } from "@mui/material";
import { ModalWrapperProps } from "../../basicModal/basicModalWrapper";

const TwoBtnModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  visible,
  onClose,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {children}
    </Dialog>
  );
};

export default TwoBtnModalWrapper;
