import styled from "@emotion/styled";

import { COLORS, FONT_SIZE } from "../../../../config/styles";

export const BasicModalContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  p {
    background-color: ${COLORS.MAIN};
    border-radius: 6px;
    font-size: ${FONT_SIZE.s};
    color: ${COLORS.WHITE};
    padding: 10px 20px;
    margin-top: 10%;
  }
`;
