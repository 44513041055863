import styled from "@emotion/styled";
import { Button, Menu, MenuItem } from "@mui/material";
import PartnerNmWithBadge from "components/common/partnerNmWithBadge";
import { COLORS, FONT_SIZE, SHADOW } from "config/styles";
import { Link } from "react-router-dom";

export const Partner = styled(Button)`
  min-width: inherit;
  padding: 0px;

  &:hover {
    background-color: transparent;
  }
`;

export const PartnerThumbImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin: 2px 0px; // 알림 팝업과 위치를 맞추기 위해
`;

export const PartnerPopup = styled(Menu)`
  & .MuiPaper-root {
    border: 1px solid ${COLORS.neutral200};
    border-radius: 8px;
    background-color: ${COLORS.white};
    box-shadow: ${SHADOW.basic};
  }

  & .MuiList-root {
    padding: 0px;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  flex-direction: column;
  padding: 24px 32px;

  :hover {
    background-color: transparent;
  }
`;

export const PartnerImgCtnr = styled.div`
  width: 73px;
  height: 73px;
  border-radius: 50%;
  overflow: hidden;
`;

export const PartnerImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const CustomPartnerNmWithBadge = styled(PartnerNmWithBadge)`
  margin-top: 20px;
`;

export const ProfilNav = styled.div`
  width: 146px;
  border-top: 1px solid ${COLORS.neutral200};
  font-weight: 500;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral600};
  padding-top: 16px;
`;

export const ProfileRoot = styled(Link)`
  display: block;
  padding: 5px;
`;

export const SignOut = styled.p`
  padding: 5px;
`;
