import { COLORS } from "config/styles";
import dayjs, { OpUnitType, QUnitType } from "dayjs";

export const labelByFlag = (flag?: number) => {
  switch (flag) {
    case 1:
      return { label: `'대기중'`, color: `${COLORS.main}` };
    case 2:
      return { label: `'진행중'`, color: `${COLORS.sub}` };
    // 3 === 후기작성
    case 3:
      return { label: `'완료'`, color: `${COLORS.neutral400}` };
    case 4:
      return { label: `'완료'`, color: `${COLORS.neutral400}` };
    case 5:
      return { label: `'마감'`, color: `${COLORS.neutral400}` };
    default:
      return { label: `'마감'`, color: "none" };
  }
};

export const priceInsertComma = (value: string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertDateTime = (
  date: string | Date,
  format: string = "YYYYMMDD"
) => {
  return dayjs(date).format(format);
};

export const diffDateTime = (
  date: string | Date,
  unit?: QUnitType | OpUnitType
) => {
  const now = dayjs();
  return dayjs(now).diff(date, unit);
};

export const lineBreakBySlash = (text: string) => {
  return text.split("|");
};

export const replaceGapBySlash = (text: string) => {
  return text.replaceAll("|", " ");
};

export const tableBodyData = (data: any[]) => {
  const myListJsonParse = data.map((i) =>
    Object.values(i)
      .map((i) => JSON.stringify(i))
      .map((i) => JSON.parse(i))
  );
  const result = myListJsonParse.map((array) => {
    return array;
  });

  return result;
};

export const withdrawItem = (arr: string[], itemNm: string) => {
  return arr.filter((category) => category !== itemNm);
};
