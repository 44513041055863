import React from "react";

import * as S from "./style";

import OfficeInfo from "./officeInfo";

import { ReactComponent as ArrowImg } from "assets/images/common/bottom-arrow.svg";

const FooterContents = () => {
  return (
    <S.FooterContents>
      <S.Office>
        <S.Title>
          <p>견적주세요 사업자정보</p>
          <ArrowImg />
        </S.Title>
        <OfficeInfo />
      </S.Office>
      <S.Tel>
        <a href="tel:000-1234-1234">
          <S.TelImg />
          <S.TelNum>000-1234-1234</S.TelNum>
        </a>
      </S.Tel>
    </S.FooterContents>
  );
};

export default FooterContents;
