import React from "react";

import * as S from "./style";

import ErrorEmg from "assets/images/common/error.png";

const ContentsError = () => {
  return (
    <S.CustomEmpty
      mainText="요청하신 페이지를 찾을 수 없습니다"
      subText={[
        "이용에 불편을 드려 죄송합니다. 해당 페이지를 찾을 수 ",
        "없습니다. 잠시 후 다시 시도해주세요.",
      ]}
      image={ErrorEmg}
      handleClick={() =>
        window.location.replace(process.env.REACT_APP_HOST_URL as string)
      }
      btnText="메인으로 돌아가기"
    />
  );
};

export default ContentsError;
