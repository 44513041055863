import { createTheme } from "@mui/material/styles";
import { COLORS } from "config/styles";
// import { createTheme } from "@mui/x-date-pickers";

export const theme = createTheme({
  palette: {
    primary: {
      main: `${COLORS.main}`,
    },
  },
  components: {},
});
