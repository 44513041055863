import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../recoil/user";

import { getPartnerApi } from "services/partner/apis";
import {
  getPartnerDashQueryKey,
  getPartnerQueryKey,
  IPartner,
} from "services/partner/types";
import { useCallback, useEffect, useState } from "react";

/**
 * partner 정보를 반환합니다.
 * 해당 hook을 통해 로그인이 된 상태인지를 파악합니다.
 * 해당 hook을 통해 partner의 정보를 사용합니다.
 */
const usePartner = () => {
  // 현재 partner의 data를 가져올 수 있는 방법은 로그인을 통해 id + 토큰을 가져온 다음 getPartnerApi를 호출하는 방법 밖에 없음
  // partner data를 react-query로 관리(서버 데이터)하고 싶어서 로직을 임시로 이렇게 작성하였음
  // 추후 token을 사용하게 된다면 id를 파라미터로 전송하는 방법 대신, token을 보내 검증하는 방식으로 변경하면 될듯함.
  const user = useRecoilValue(userAtom);
  const queryClient = useQueryClient();

  return useQuery<IPartner>(
    [getPartnerQueryKey],
    () => getPartnerApi({ id: user?.id }),
    {
      enabled: !!user?.id,
      staleTime: Infinity,
      onSuccess: () => {
        queryClient.refetchQueries([getPartnerDashQueryKey]);
      },
    }
  );
};

export default usePartner;
