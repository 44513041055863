import { API } from "config/api";
import {
  ICategoryListResponseData,
  IPartnerDashResponseData,
  IPartnerProfileInput,
  IPartnerResponseData,
  IPartnerStatsByMonthResponseData,
  ISearchItemResponseData,
} from "./types";

export const getPartnerApi = async (body: { id?: number }) => {
  const { data } = await API.post<IPartnerResponseData>(
    "/sending/myinfo",
    body
  );
  return data.data;
};

export const getPartnerDashApi = async (body: { partnerId?: number }) => {
  const { data } = await API.post<IPartnerDashResponseData>(
    "/partner/dash",
    body
  );

  return data.data;
};

export const getPartnerStatsByMonthApi = async (body: {
  partnerId?: number;
}) => {
  const { data } = await API.post<IPartnerStatsByMonthResponseData>(
    "/sending/partnermonth",
    body
  );

  return data.data;
};

export const postPartnerProfileApi = async (body: IPartnerProfileInput) => {
  const { data } = await API.post("/sending/profile", body);
  return data;
};

export const searchItemApi = async (body: { itemNm: string }) => {
  const { data } = await API.post<ISearchItemResponseData>(
    "/sending/searchitem",
    body
  );
  return data.data;
};

export const getCategoryList = async () => {
  const { data } = await API.get<ICategoryListResponseData>("/sending/item");
  return data.data;
};
