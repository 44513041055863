import { useState } from "react";

/** 
 * 드롭다운 메뉴를 위해 사용됩니다.
 * 해당 hook보다 mui를 사용하는 것을 추천드립니다.
 */
const useDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, setAnchorEl, open, handleClick, handleClose };
};

export default useDropdown;
