import React, { HTMLAttributes } from "react";

import * as S from "./style";

interface Props extends HTMLAttributes<HTMLDivElement> {
  partnerNm: string;
}

const PartnerNmWithBadge: React.FC<Props> = ({ partnerNm, ...props }) => {
  return (
    <S.UserName {...props}>
      <p>정회원</p>
      <span>{partnerNm}</span>
    </S.UserName>
  );
};

export default PartnerNmWithBadge;
