import styled from "@emotion/styled";

import { COLORS, FONT_SIZE } from "config/styles";

export const Nav = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const NavList = styled.li`
  font-weight: 500;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral200};
  margin-right: 29px;
`;

export const SNS = styled.ul`
  display: flex;
`;

export const SNSList = styled.li`
  width: 32px;
  height: 32px;
  margin-left: 20px;
`;
