import styled from "@emotion/styled";
import { ANIMATIONS } from "config/styles";

export const RootLoading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImg = styled.div`
  width: 350px;
`;

export const Svg = styled.svg`
  width: 100%;
`;

export const G1 = styled.g`
  transform: rotate(0deg);
  animation: ${ANIMATIONS.rotate} 0.7s forwards;
  animation-delay: 2s;
`;

export const Path1 = styled.path`
  opacity: 0;
  transform: translateX(100%);
  animation: ${ANIMATIONS.fadeInLeft} 0.7s forwards;
`;

export const Path2 = styled.path`
  opacity: 0;
  transform: translateX(100%);
  animation: ${ANIMATIONS.fadeInLeft} 0.7s forwards;
  animation-delay: 0.2s;
`;

export const Path3 = styled.path`
  opacity: 0;
  transform: translateX(100%);
  animation: ${ANIMATIONS.fadeInLeft} 0.7s forwards;
  animation-delay: 0.4s;
`;

export const Path4 = styled.path`
  opacity: 0;
  transform: translateX(100%);
  animation: ${ANIMATIONS.fadeInLeft} 0.7s forwards;
  animation-delay: 0.6s;
`;

export const Path5 = styled.path`
  opacity: 0;
  transform: translateX(100%);
  animation: ${ANIMATIONS.fadeInLeft} 0.5s forwards;
  animation-delay: 0.8s;
`;
