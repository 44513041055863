import styled from "@emotion/styled";
import RouteBtn from "components/common/routeBtn";
import { COLORS, FONT_SIZE, SHADOW } from "config/styles";

export const EmptyBg = styled.div`
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  height: 100%;
  max-height: 675px;
  border-radius: 8px;
  box-shadow: ${SHADOW.basic};
  background-color: ${COLORS.white};
  padding: 58px;

  > p {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: ${FONT_SIZE.xxxl};
    color: ${COLORS.neutral700};
    padding-bottom: 25px;
    border-bottom: 1px solid ${COLORS.neutral200};
  }
`;

export const EmptyBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 64px;
  }

  p {
    width: max-content;
    margin-top: 16px;
  }
`;

export const SubText = styled.div`
  font-size: ${FONT_SIZE.s};
  color: ${COLORS.neutral500};

  &:first-of-type {
    margin-top: 16px;
  }
`;

export const CustomRouteBtn = styled(RouteBtn)`
  margin-top: 24px;
`;

export const NextBtn = styled.div`
  color: ${COLORS.neutral600};
  text-decoration: underline;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
`;
