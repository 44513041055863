import React from "react";

import * as S from "./style";

const RootLoading = () => {
  return (
    <S.RootLoading>
      <S.LogoImg>
        <S.Svg
          width="1012"
          height="173"
          viewBox="0 0 1012 173"
          fill="none"
          xmlns="http://www.w3.org/2000/S.Svg"
        >
          <rect
            x="0.450073"
            y="0.0202637"
            width="171.991"
            height="172"
            rx="20"
            fill="url(#paint0_linear_423_16462)"
          />
          <S.G1 clipPath="url(#clip0_423_16462)">
            <g>
              <path
                d="M76.6205 82.2873H40.0145C36.4006 82.2873 33.4472 79.3313 33.4472 75.714V39.0743C33.4472 35.457 36.4006 32.501 40.0145 32.501H76.6205C80.2344 32.501 83.1878 35.457 83.1878 39.0743V75.714C83.1878 79.3313 80.2344 82.2873 76.6205 82.2873Z"
                fill="white"
              />
              <path
                d="M71.8405 54.5532H44.5998V59.3763H71.8405V54.5532Z"
                fill="#EF6D93"
              />
            </g>
            <g>
              <path
                d="M76.6205 139.269H40.0145C36.4006 139.269 33.4472 136.313 33.4472 132.696V96.0563C33.4472 92.439 36.4006 89.4829 40.0145 89.4829H76.6205C80.2344 89.4829 83.1878 92.439 83.1878 96.0563V132.696C83.1878 136.313 80.2344 139.269 76.6205 139.269Z"
                fill="white"
              />
              <path
                d="M58.1888 108.307C60.1418 108.307 61.7251 106.723 61.7251 104.768C61.7251 102.813 60.1418 101.228 58.1888 101.228C56.2358 101.228 54.6526 102.813 54.6526 104.768C54.6526 106.723 56.2358 108.307 58.1888 108.307Z"
                fill="#EF6D93"
              />
              <path
                d="M58.1888 128.844C60.1418 128.844 61.7251 127.259 61.7251 125.305C61.7251 123.35 60.1418 121.765 58.1888 121.765C56.2358 121.765 54.6526 123.35 54.6526 125.305C54.6526 127.259 56.2358 128.844 58.1888 128.844Z"
                fill="#EF6D93"
              />
              <path
                d="M73.4996 112.625H42.8392V117.448H73.4996V112.625Z"
                fill="#EF6D93"
              />
            </g>
            <g>
              <path
                d="M134.133 139.269H97.5271C93.9131 139.269 90.9598 136.313 90.9598 132.696V96.0563C90.9598 92.439 93.9131 89.4829 97.5271 89.4829H134.133C137.747 89.4829 140.7 92.439 140.7 96.0563V132.696C140.7 136.313 137.786 139.269 134.133 139.269Z"
                fill="white"
              />
              <path
                d="M118.356 98.6997H113.537V129.388H118.356V98.6997Z"
                fill="#EF6D93"
              />
              <path
                d="M131.257 111.652H100.597V116.475H131.257V111.652Z"
                fill="#EF6D93"
              />
            </g>
            <g>
              <path
                d="M134.133 82.2873H97.5271C93.9131 82.2873 90.9598 79.3313 90.9598 75.714V39.0743C90.9598 35.457 93.9131 32.501 97.5271 32.501H134.133C137.747 32.501 140.7 35.457 140.7 39.0743V75.714C140.7 79.3313 137.786 82.2873 134.133 82.2873Z"
                fill="white"
              />
              <path
                d="M128.759 48.1506L125.352 44.7402L103.672 66.4402L107.079 69.8505L128.759 48.1506Z"
                fill="#EF6D93"
              />
              <path
                d="M128.732 66.4404L107.052 44.7405L103.645 48.1509L125.325 69.8508L128.732 66.4404Z"
                fill="#EF6D93"
              />
            </g>
          </S.G1>
          <g clipPath="url(#clip1_423_16462)">
            <g clipPath="url(#clip2_423_16462)">
              <S.Path1
                d="M299.081 29.8376H248.38V7.91274H325.02V25.9225H350.567V6.34668H376.507V116.363H350.567V85.8244H318.732V64.2911H350.567V47.8474H322.269C314.409 81.5178 288.862 99.5275 244.45 103.443V81.9093C281.002 76.8196 299.081 58.8098 299.081 29.8376ZM378.865 142.203V163.736H257.027V108.141H282.967V142.594H378.865V142.203Z"
                fill="#EF6D93"
              />
              <S.Path2
                d="M404.805 29.8376V7.91274H488.126V29.8376H459.042V31.4037C459.042 58.0268 470.44 68.5977 490.877 72.5129V94.4378C472.405 92.0887 455.898 84.2584 446.859 72.1214C437.426 84.2584 420.133 94.0463 402.054 96.3954V74.4705C422.098 70.1638 433.889 58.4183 433.889 31.4037V29.8376H404.805ZM411.879 126.151V104.617H533.324V165.694H507.385V126.542H411.879V126.151ZM533.324 6.34668V97.5699H507.385V60.7674H479.087V39.234H507.385V6.34668H533.324Z"
                fill="#EF6D93"
              />
              <S.Path3
                d="M614.681 125.759H557.692V103.834H698.002V125.759H640.62V165.302H614.681V125.759ZM690.142 30.2293H640.62C643.372 52.9372 664.595 65.0742 691.714 69.7724V92.0888C665.381 88.5652 641.013 78.3857 627.651 63.5081C614.288 78.3857 589.92 88.5652 563.587 92.0888V69.7724C590.313 65.4657 612.323 53.3287 614.681 30.2293H565.159V7.91284H690.142V30.2293Z"
                fill="#EF6D93"
              />
              <S.Path4
                d="M746.737 126.151C739.663 137.505 726.3 148.467 711.365 152.774V130.066C726.693 121.061 734.161 107.358 734.161 72.5129V7.91274H758.921V72.9044C758.921 107.358 766.782 121.452 781.717 130.066V153.165C767.175 148.859 753.812 137.896 746.737 126.151ZM814.338 7.12971V161.387H790.363V82.6923H769.926V60.7674H790.363V7.12971H814.338ZM848.924 6.34668V162.953H824.557V6.34668H848.924Z"
                fill="#EF6D93"
              />
              <S.Path5
                d="M1007.31 55.2861C1007.31 70.1637 1001.42 81.5177 991.2 89.7395C988.449 91.6971 986.09 93.6547 982.946 94.8292V134.764H1011.64V156.689H871.327V134.764H900.804V95.2207C897.267 93.6547 894.515 91.6971 891.764 89.7395C881.545 81.9092 876.043 70.5552 876.043 55.2861C876.043 40.017 881.938 28.663 891.764 20.8327C903.162 11.8278 920.848 7.12964 941.678 7.12964C962.116 7.12964 979.802 11.8278 991.593 20.8327C1001.42 28.663 1007.31 40.4085 1007.31 55.2861ZM980.981 55.2861C980.981 47.4558 978.23 41.583 972.728 37.2764C965.653 31.7952 954.648 29.4461 941.285 29.4461C927.923 29.4461 916.918 31.7952 909.843 37.2764C904.341 41.1915 901.59 47.4558 901.59 55.2861C901.59 63.1164 904.341 68.9892 909.843 73.2958C916.918 78.7771 927.923 81.5177 941.285 81.5177C954.648 81.5177 965.653 78.7771 972.728 73.2958C978.23 68.9892 980.981 63.1164 980.981 55.2861ZM957.006 101.877C951.897 102.66 946.788 103.051 941.285 103.051C936.176 103.051 931.067 102.66 925.957 102.268V134.764H956.613V101.877H957.006Z"
                fill="#EF6D93"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_423_16462"
              x1="10.1962"
              y1="0.0202698"
              x2="162.131"
              y2="172.013"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF6D93" />
              <stop offset="1" stopColor="#F2789C" />
            </linearGradient>
            <clipPath id="clip0_423_16462">
              <rect
                width="107.771"
                height="107.039"
                fill="white"
                transform="translate(32.9291 32.501)"
              />
            </clipPath>
            <clipPath id="clip1_423_16462">
              <rect
                width="767.187"
                height="172"
                fill="white"
                transform="translate(244.45 0.0202637)"
              />
            </clipPath>
            <clipPath id="clip2_423_16462">
              <rect
                width="767.187"
                height="159.347"
                fill="white"
                transform="translate(244.45 6.34668)"
              />
            </clipPath>
          </defs>
        </S.Svg>
      </S.LogoImg>
    </S.RootLoading>
  );
};

export default RootLoading;
