import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { COLORS, FONT_SIZE, SHADOW } from "config/styles";

export const CustomLink = styled.div`
  display: block;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  background-color: ${COLORS.main};
  border-radius: 8px;
  font-weight: 700;
  box-shadow: ${SHADOW.basic};
  font-size: ${FONT_SIZE.l};
  color: ${COLORS.white};
  padding: 0px 20px;

  svg {
    width: 36px;
    height: 36px;
    margin-left: 17px;
  }
`;
