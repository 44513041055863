import React from "react";
import { Outlet } from "react-router-dom";

import * as S from "./style";

import Header from "../../header";
import Footer from "../../footer";
import Modal from "../../modal";
import AsyncBoundary from "../../asyncBoundary";
import RootLoading from "../../loadingUI/rootLoading";
import ProfileAside from "../../pageWrapper/profileAside";
import usePartner from "hooks/partner/usePartner";

const BasicLayout = () => {
  const { data: partner } = usePartner();

  return (
    <>
      <Header />
      <S.CommonLayout>
        <S.Main>
          <ProfileAside partner={partner} />
          <S.PageMainCtnr>
            <Outlet />
          </S.PageMainCtnr>
        </S.Main>
      </S.CommonLayout>
      <Footer />
    </>
  );
};

export default BasicLayout;
