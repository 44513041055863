import React, { ReactElement, useEffect } from "react";
import { Modal, Backdrop, Fade } from "@mui/material";
import { modalStateAtom } from "../../../../../recoil/modal";
import { Resetter, useResetRecoilState } from "recoil";

export interface ModalWrapperProps {
  children: ReactElement;
  visible: boolean;
  onClose: Resetter;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  visible,
  onClose,
}) => {
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>{children}</Fade>
    </Modal>
  );
};

export default ModalWrapper;
