import { useQuery } from "@tanstack/react-query";

import { getPartnerDashApi } from "services/partner/apis";
import { getPartnerDashQueryKey } from "services/partner/types";

import { partnerDashInitialData } from "utils/const";

import usePartner from "./usePartner";

/**
 * 파트너의 총 받은요청, 보낸견적, 거래완료, 평점, 리뷰 정보를 반환합니다.
 */
const usePartnerDash = () => {
  const { data: partner } = usePartner();

  return useQuery(
    [getPartnerDashQueryKey],
    () => getPartnerDashApi({ partnerId: partner?.id }),
    {
      enabled: !!partner,
      placeholderData: partnerDashInitialData,
      staleTime: 1000, // 지워도 됩니다.
    }
  );
};

export default usePartnerDash;
