import React, { useState } from "react";

import * as S from "./style";

import useAlert from "hooks/alert/useAlert";
import { PATH_ALERT, PATH_CHAT, PATH_SENDING } from "routes/paths";
import { convertDateTime, diffDateTime } from "services/common/utils";

import { ReactComponent as MoreImg } from "assets/images/common/more-left.svg";
import useDropdown from "hooks/common/useDropdown";
import { useNavigate } from "react-router-dom";

const AlertPopup = () => {
  const { alertList } = useAlert();
  const { anchorEl, open, handleClick, handleClose } = useDropdown();
  const navigate = useNavigate();
  const handleClickAlert = (callType: number, sendingId: number) => {
    switch (callType) {
      case 1:
        navigate(PATH_SENDING.send(sendingId), { state: sendingId });
        break;
      case 2:
        break;
      case 3:
        navigate(PATH_CHAT.all);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <S.AlertCtnr
        id="alert-button"
        aria-controls={open ? "alert-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <S.AlertIcon>
          <S.AlertImg />
        </S.AlertIcon>
      </S.AlertCtnr>
      <S.AlertPopup
        id="alert-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "alert-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <S.CustomMenuItem onClick={handleClose} disableRipple>
          <S.AlertPopupHead>
            <S.Title>알림</S.Title>
            <S.CustomRouteBtn
              isIcon={false}
              handleClick={() => navigate(PATH_ALERT.all)}
            >
              전체보기
            </S.CustomRouteBtn>
          </S.AlertPopupHead>
          <S.AlertListCtnr>
            {alertList?.map(
              ({ title, contents, createdAt, sendingId, callType }, index) => (
                <S.AlertList
                  key={index + title}
                  onClick={() => handleClickAlert(callType, sendingId)}
                >
                  <S.ListTop>
                    <S.CustomStrongText text="알림" />
                    <S.Date>
                      {/* <span>{diffDateTime(createdAt, "day")}일</span>&nbsp;전 */}
                      <span>{convertDateTime(createdAt, "YYYY/MM/DD")}</span>
                      &nbsp;
                    </S.Date>
                  </S.ListTop>
                  <S.ListBot>
                    <S.ListTextCtnr>
                      <S.ListTit>{title}</S.ListTit>
                      <S.ListContents>{contents}</S.ListContents>
                    </S.ListTextCtnr>
                    <S.MoreBtn>
                      <MoreImg />
                    </S.MoreBtn>
                  </S.ListBot>
                </S.AlertList>
              )
            )}
          </S.AlertListCtnr>
        </S.CustomMenuItem>
      </S.AlertPopup>
    </>
  );
};

export default AlertPopup;
