import React, { HTMLAttributes, ReactNode } from "react";

import * as S from "./style";

import { ReactComponent as RightArrowImg } from "assets/images/common/right-arrow.svg";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  handleClick: VoidFunction;
  children: ReactNode;
  isIcon?: boolean;
}

const RouteBtn: React.FC<Props> = ({
  handleClick,
  children,
  isIcon = true,
  ...props
}) => {
  return (
    <S.CustomLink onClick={handleClick}>
      <S.Button {...props}>
        {children}
        {isIcon && <RightArrowImg />}
      </S.Button>
    </S.CustomLink>
  );
};

export default RouteBtn;
