import { atom } from "recoil";
import { ModalType } from "../components/common/modal";

export const modalStateAtom = atom<ModalType>({
  key: "ModalState",
  default: {
    key: "",
    contents: "",
  },
});
