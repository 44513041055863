import { useResetRecoilState, useSetRecoilState } from "recoil";
import { modalStateAtom } from "../../recoil/modal";
import { ModalType } from "../../components/common/modal";

/**
 * 모달창을 사용하기 위한 훅입니다.
 * 모달창은 key를 통해 선택할 수 있습니다.
 */
const useModal = () => {
  const setModalState = useSetRecoilState(modalStateAtom);
  const resetModal = useResetRecoilState(modalStateAtom);

  const openModal = (modal: ModalType) => {
    setModalState(modal);
  };

  const closeModal = () => {
    resetModal();
  };

  return {
    openModal,
    closeModal,
  };
};

export default useModal;
