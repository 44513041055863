import { Suspense, lazy, ElementType } from "react";
import { useRoutes, useLocation, Navigate } from "react-router-dom";

import AsyncBoundary from "components/common/asyncBoundary";
import HeaderOnlyLayout from "components/common/layout/headerOnlyLayout";
import ContentsLoading from "components/common/loadingUI/contentsLoading";
import ContentsError from "components/error/contentsError";
import BasicLayout from "../components/common/layout/basicLayout";
import PrivateRoute from "../components/common/privateRoute";

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();
  const isAuth = pathname.includes("/auth");

  return (
    <AsyncBoundary
      errorFallback={<ContentsError />}
      suspenseFallback={<ContentsLoading isAuth={isAuth} />}
    >
      <Component {...props} />
    </AsyncBoundary>
  );
};

const Main = Loadable(lazy(() => import("../pages/main")));
const Partnership = Loadable(lazy(() => import("../pages/partnership")));
const Policy = Loadable(lazy(() => import("../pages/policy")));

const Faq = Loadable(lazy(() => import("../pages/faq")));
const FaqDetail = Loadable(lazy(() => import("../pages/faq/detail")));

const Notice = Loadable(lazy(() => import("../pages/notice")));
const NoticeDetail = Loadable(lazy(() => import("../pages/notice/detail")));

const SignIn = Loadable(lazy(() => import("../pages/auth/signIn")));
const SignInCallback = Loadable(
  lazy(() => import("../pages/auth/signIn/callback"))
);
const SignUp = Loadable(lazy(() => import("../pages/auth/signUp")));
const FindAuth = Loadable(lazy(() => import("../pages/auth/find")));
// const ChangePassword = Loadable(
//   lazy(() => import("../pages/auth/changePassword"))
// );

const Sending = Loadable(lazy(() => import("../pages/sending")));
const Send = Loadable(lazy(() => import("../pages/sending/send")));

const Invoice = Loadable(lazy(() => import("../pages/invoice")));
const Sent = Loadable(lazy(() => import("../pages/invoice/sent")));

const ChatAll = Loadable(lazy(() => import("../pages/chat/all")));
const ChatDetail = Loadable(lazy(() => import("../pages/chat/detail")));

const AlertAll = Loadable(lazy(() => import("../pages/alert/all")));
const AlertDetail = Loadable(lazy(() => import("../pages/alert/detail")));

const Profile = Loadable(lazy(() => import("../pages/profile")));
const Register = Loadable(lazy(() => import("../pages/profile/register")));
const Category = Loadable(lazy(() => import("../pages/profile/category")));

const NotFound = Loadable(lazy(() => import("../pages/404")));

const router = () => {
  return useRoutes([
    // Main Routes
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        { element: <Main />, index: true },
        { path: "partnership", element: <Partnership /> },
        { path: "policy", element: <Policy /> },

        // Sending Routes
        {
          path: "sending",
          children: [
            {
              element: (
                <PrivateRoute>
                  <Sending />
                </PrivateRoute>
              ),
              index: true,
            },
            {
              path: ":sendingId",
              element: (
                <PrivateRoute>
                  <Send />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Invoice Routes
        {
          path: "invoice",
          children: [
            {
              element: (
                <PrivateRoute>
                  <Invoice />
                </PrivateRoute>
              ),
              index: true,
            },
            {
              path: ":invoiceId",
              element: (
                <PrivateRoute>
                  <Sent />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Chat Routes
        {
          path: "chat",
          children: [
            { element: <Navigate to="/chat/all" replace />, index: true },
            {
              path: "all",
              element: (
                <PrivateRoute>
                  <ChatAll />
                </PrivateRoute>
              ),
            },
            {
              path: ":chatId",
              element: (
                <PrivateRoute>
                  <ChatDetail />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Profile Routes
        {
          path: "profile",
          children: [
            {
              element: (
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              ),
              index: true,
            },
            {
              path: "register",
              element: (
                <PrivateRoute>
                  <Register />
                </PrivateRoute>
              ),
            },
            {
              path: "category",
              element: (
                <PrivateRoute>
                  <Category />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Alert Routes
        {
          path: "alert",
          children: [
            { element: <Navigate to="/alert/all" replace />, index: true },
            {
              path: "all",
              element: (
                <PrivateRoute>
                  <AlertAll />
                </PrivateRoute>
              ),
            },
            {
              path: ":alertId",
              element: (
                <PrivateRoute>
                  <AlertDetail />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Faq Routes
        {
          path: "faq",
          children: [
            { element: <Navigate to="/faq/all" replace />, index: true },
            {
              path: "all",
              element: (
                <PrivateRoute>
                  <Faq />
                </PrivateRoute>
              ),
            },
            {
              path: ":faqId",
              element: (
                <PrivateRoute>
                  <FaqDetail />
                </PrivateRoute>
              ),
            },
          ],
        },

        // Notice Routes
        {
          path: "notice",
          children: [
            { element: <Navigate to="/notice/all" replace />, index: true },
            {
              path: "all",
              element: (
                <PrivateRoute>
                  <Notice />
                </PrivateRoute>
              ),
            },
            {
              path: ":noticeId",
              element: (
                <PrivateRoute>
                  <NoticeDetail />
                </PrivateRoute>
              ),
            },
          ],
        },
      ],
    },

    // Auth Routes
    {
      path: "auth",
      element: <HeaderOnlyLayout />,
      children: [
        {
          path: "signin",
          children: [
            { element: <SignIn />, index: true },
            { path: "kakao", element: <SignInCallback type="kakao" /> },
            { path: "naver", element: <SignInCallback type="naver" /> },
          ],
        },
        { path: "signup", element: <SignUp /> },
        { path: "find", element: <FindAuth /> },
        // { path: "change-password", element: <ChangePassword /> },
      ],
    },

    // Error Routes
    { path: "*", element: <NotFound /> },
  ]);
};

export default router;
