import styled from "@emotion/styled";
import { AccordionDetails } from "@mui/material";
import { COLORS, FONT_SIZE } from "config/styles";

export const TableCntr = styled(AccordionDetails)``;

export const TableRow = styled.div`
  display: flex;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.neutral400};
`;

export const RowTitle = styled.p`
  width: 80px;
`;

export const RowContents = styled.p`
  margin-left: 21px;
`;
