import AsyncBoundary from "components/common/asyncBoundary";
import Header from "components/common/header";
import RootLoading from "components/common/loadingUI/rootLoading";
import Modal from "components/common/modal";
import React from "react";
import { Outlet } from "react-router-dom";

import * as S from "./style";

const HeaderOnlyLayout = () => {
  return (
    <>
      <Header />
      <S.HeaderOnlyLayout>
        <Outlet />
      </S.HeaderOnlyLayout>
    </>
  );
};

export default HeaderOnlyLayout;
