import styled from "@emotion/styled";

import { COLORS } from "../../../config/styles";

interface TextProp {
  fontSize: string;
}

export const Text = styled.span<TextProp>`
  font-weight: 700;
  font-size: ${({ fontSize }) => `${fontSize}`};
  color: ${COLORS.pointRed};
`;
