import { atom, AtomEffect, DefaultValue } from "recoil";
import { SignInUserInfo } from "../services/auth/types";
import {
  getUserInLocalStorage,
  removeUserInLocalStorage,
  setUserInLocalStorage,
} from "../services/auth/utils";

const persistAtom: AtomEffect<any> = ({ setSelf, onSet }) => {
  setSelf(
    getUserInLocalStorage() != null
      ? getUserInLocalStorage()
      : new DefaultValue()
  );
  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      removeUserInLocalStorage();
    } else {
      setUserInLocalStorage(newValue);
    }
  });
};

export const userAtom = atom<SignInUserInfo | null>({
  key: "UserAtom",
  default: null,
  effects: [persistAtom],
});
