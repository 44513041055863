import { ICommonResponseData } from "services/common/types";

export const getAlertListApiQueryKey = "getAlertListApiQuery";

// Alert
export interface IAlertRequestBody {
  userId?: number;
  partneryn: boolean;
}

export interface IAlert {
  createdAt: string;
  title: string;
  contents: string;
  status: number;
  goUrl: string;
  callType: number;
  sendingId: number;
  partnerId: number;
  userId: number;
  invoiceId: number;
}

export interface IAlertResponseData extends ICommonResponseData {
  data: IAlert[];
}
