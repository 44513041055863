import React from "react";

import * as S from "./style";

interface Props {
  isAuth: boolean;
}

const ContentsLoading: React.FC<Props> = ({ isAuth }) => {
  return isAuth ? (
    <S.AuthLoading>
      <S.CustomCircularProgress />
    </S.AuthLoading>
  ) : (
    <S.BasicLoading>
      <S.CustomCircularProgress />
    </S.BasicLoading>
  );
};

export default ContentsLoading;
