import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import * as S from "./style";

import usePartner from "hooks/partner/usePartner";
import useSignOut from "../../../../hooks/auth/useSignOut";

import { ReactComponent as LogoImg } from "assets/images/common/logo.svg";

import AuthBtnWrapper from "./authBtnWrapper";
import { headerNav } from "utils/const";
import AsyncBoundary from "components/common/asyncBoundary";
import useAlert from "hooks/alert/useAlert";
import { PATH_ALERT } from "routes/paths";
import RouteBtn from "components/common/routeBtn";
import AlertPopup from "components/alert/alertPopup";
import { Button, Menu, MenuItem } from "@mui/material";

const DesktopHeader = () => {
  const { pathname } = useLocation();
  const { signOut } = useSignOut();
  const { data: partner } = usePartner();

  return (
    <S.HeaderTag pathname={pathname}>
      <S.HeaderWrapper>
        <S.Logo>
          <Link to="/">
            <LogoImg />
          </Link>
        </S.Logo>
        <S.HeaderContentsCtnr>
          {partner && (
            <>
              <S.Nav>
                <ul>
                  {headerNav.map(({ path, title }) => (
                    <li key={path}>
                      <Link to={path}>{title}</Link>
                    </li>
                  ))}
                </ul>
              </S.Nav>
              <AlertPopup />
            </>
          )}
          <AuthBtnWrapper />
        </S.HeaderContentsCtnr>
      </S.HeaderWrapper>
    </S.HeaderTag>
  );
};

export default DesktopHeader;
