import React from "react";

import * as S from "./style";

import { appstoreURI, googlePlayURI } from "utils/const";

import GoogleplayImg from "assets/images/footer/googleplay.png";
import AppstoreImg from "assets/images/footer/appstore.png";

const DownloadApp = () => {
  return (
    <S.DownloadAppBtnCtnr>
      <S.DownloadAppBtn>
        <a href={googlePlayURI} target="_blank">
          <S.Googleplay src={GoogleplayImg} alt="구글플레이 바로가기" />
        </a>
      </S.DownloadAppBtn>
      <S.DownloadAppBtn>
        <a href={appstoreURI} target="_blank">
          <S.Appstore src={AppstoreImg} alt="앱스토어 바로가기" />
        </a>
      </S.DownloadAppBtn>
    </S.DownloadAppBtnCtnr>
  );
};

export default DownloadApp;
