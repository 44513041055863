import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { COLORS, SHADOW } from "config/styles";

export const AuthLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 80px);
`;

export const CustomCircularProgress = styled(CircularProgress)`
  color: ${COLORS.main};
`;

export const BasicLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 690px; // Same aside bar height
  background-color: ${COLORS.white};
  box-shadow: ${SHADOW.basic};
  border-radius: 8px;
`;
