import {
  PATH_CHAT,
  PATH_FAQ,
  PATH_INVOICE,
  PATH_PAGE,
  PATH_PROFILE,
  PATH_SENDING,
} from "routes/paths";

import { IPartnerDash, IPartnerStatsByMonth } from "services/partner/types";

import { INavList, IPolicyList } from "types/common";

import Facebook from "assets/images/footer/facebook.svg";
import Instagram from "assets/images/footer/instagram.svg";
import Blog from "assets/images/footer/blog.svg";
import Youtube from "assets/images/footer/youtube.svg";

// Header
export const headerNav: INavList[] = [
  {
    title: "받은요청",
    path: PATH_SENDING.root,
  },
  {
    title: "보낸견적",
    path: PATH_INVOICE.root,
  },
  {
    title: "채팅",
    path: PATH_CHAT.all,
  },
];

// Partner dash
export const partnerDashInitialData: IPartnerDash = {
  invoiceCnt: 0,
  tradeCnt: 0,
  recvCnt: 0,
  avgStar: "0.00",
  reviewCnt: 0,
};

// Chart
export const chartInitialData: IPartnerStatsByMonth[] = [
  { id: 1, month: "202301", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 2, month: "202302", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 3, month: "202303", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 4, month: "202304", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 5, month: "202305", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 6, month: "202306", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 7, month: "202307", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 8, month: "202308", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 9, month: "202309", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 10, month: "202310", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 11, month: "202311", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
  { id: 12, month: "202312", recvCnt: 0, invoiceCnt: 0, tradeCnt: 0 },
];

export const partnerDashNav: INavList[] = [
  {
    title: "채팅",
    path: PATH_CHAT.all,
  },
  {
    title: "프로필",
    path: PATH_PROFILE.root,
  },
  {
    title: "FAQ",
    path: PATH_FAQ.all,
  },
  {
    title: "공지사항",
    path: PATH_PAGE.notice,
  },
];

// Category
export const categoryOption = [
  "핸드폰/통신",
  "렌탈",
  "보험",
  "자동차",
  "이사",
  "상조",
  "인테리어",
];

// Footer
export const footerNav: INavList[] = [
  {
    title: "이용약관",
    path: PATH_PAGE.policy,
  },
  {
    title: "개인정보처리방침",
    path: "https://jealous-piper-ae4.notion.site/cdc5dd8f31f74a988deb6bba6f23b316",
  },
  {
    title: "제휴문의",
    path: PATH_PAGE.partnership,
  },
];

export const footerSNS = [
  {
    img: Facebook,
    alt: "페이스북 바로가기",
    url: "https://facebook.com",
  },
  {
    img: Instagram,
    alt: "인스타그램 바로가기",
    url: "https://instagram.com",
  },
  {
    img: Blog,
    alt: "블로그 바로가기",
    url: "https://naver.com",
  },
  {
    img: Youtube,
    alt: "유튜브 바로가기",
    url: "https://youtube.com",
  },
];

export const googlePlayURI =
  "https://play.google.com/store/apps/details?id=com.kviruslab.gyunju";

export const appstoreURI =
  "https://apps.apple.com/kr/app/%EA%B2%AC%EC%A0%81%EC%A3%BC%EC%84%B8%EC%9A%94-%ED%9C%B4%EB%8C%80%ED%8F%B0-%EC%9D%B4%EC%82%AC-%EB%A0%88%EC%8A%A8-%EA%B3%BC%EC%99%B8-%EC%B2%AD%EC%86%8C-%EB%93%B1/id1658453308";

// Sending, Invoice
export const SHOW_LIST_COUNT = 3;
export const INVOICE_CONTENTS_TEXT = 1000;

// Common table header
export const tableHeadList = ["NO", "카테고리", "제목", "작성시간"];

// Faq
export const faqMenu = ["전체"];

// Notice
export const noticeMenu = ["전체"];

// Alert
export const alertMenu = ["전체"];

// Policy
export const policyList: IPolicyList[] = [
  {
    bigTitle: "제 1장 총칙",
    bigContents: [
      {
        middleTitle: "제 1조 (목적)",
        firstLine: "",
        middleContents: [
          {
            smallTitle:
              "이 약관은 이용자가 주식회사 견적주세요(이하 “회사”)가 운영하는 인터넷 서비스 사이트(이하 “사이트” 또는 “견주”)를 통해 제공하는 인터넷 전자상거래 관련 서비스(이하 “서비스”)와 관련하여 회사와 이용자의 권리, 의무, 책임사항을 규정함을 목적으로 합니다. 또한 본 약관은 유무선 PC통신, 태블릿 PC, 스마트폰(아이폰, 안드로이드폰 등) 어플리케이션 및 모바일웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지 않는 한 준용됩니다.1",
            smallContents: [],
          },
          {
            smallTitle:
              "이 약관은 이용자가 주식회사 견적주세요(이하 “회사”)가 운영하는 인터넷 서비스 사이트(이하 “사이트” 또는 “견주”)를 통해 제공하는 인터넷 전자상거래 관련 서비스(이하 “서비스”)와 관련하여 회사와 이용자의 권리, 의무, 책임사항을 규정함을 목적으로 합니다. 또한 본 약관은 유무선 PC통신, 태블릿 PC, 스마트폰(아이폰, 안드로이드폰 등) 어플리케이션 및 모바일웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지 않는 한 준용됩니다.2",
            smallContents: [],
          },
        ],
      },
      {
        middleTitle: "제 2조 (정의)",
        firstLine: "이 약관에서 사용하는 용어의 정의는 다음과 같습니다.",
        middleContents: [
          {
            smallTitle:
              "회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.",
            smallContents: [],
          },
          {
            smallTitle:
              "이 약관은 이용자가 주식회사 견적주세요(이하 “회사”)가 운영하는 인터넷 서비스 사이트(이하 “사이트” 또는 “견주”)를 통해 제공하는 인터넷 전자상거래 관련 서비스(이하 “서비스”)와 관련하여 회사와 이용자의 권리, 의무, 책임사항을 규정함을 목적으로 합니다. 또한 본 약관은 유무선 PC통신, 태블릿 PC, 스마트폰(아이폰, 안드로이드폰 등) 어플리케이션 및 모바일웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지 않는 한 준용됩니다.3",
            smallContents: [
              "본인 실명이 아니거나 다른 사람의 명의를 사용하여 신청하였을 때",
              "서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때",
            ],
          },
        ],
      },
    ],
  },
];

// Chat
export const GET_CHAT_COUNT = 20;
