import React from "react";
import DaumPostcode from "react-daum-postcode";

import { ModalProps } from "../basicModal";
import LocationModalWrapper from "./locationModalWrapper";

const LocationModal: React.FC<ModalProps> = ({
  visible,
  onClose,
  callback,
}) => {
  return (
    <LocationModalWrapper visible={visible} onClose={onClose}>
      <DaumPostcode onComplete={callback} />
    </LocationModalWrapper>
  );
};

export default LocationModal;
